<template>
  <div class="container">
    <nav-bar :nav-info ="navInfo" ></nav-bar>
    <!-- 下拉退出登录 -->
    <div class="destoryLogin">
      <van-dropdown-menu>
        <van-dropdown-item  ref="item">
          <div class="destoryLogin_box">
           <div class="border_sjx"></div>
            <div class="destoryLogin_box_img" >
              <img :src="require('../../assets/img/icon/destoryLogin.png')" alt="">
            </div>
            <div class="destoryLogin_box_text" @click="exitLogin">
              退出登录
            </div>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="img_top">
      <van-image
      fill
        :src="require('../../assets/banner1.png')"
      />
    </div>
    <div class="order_info">
      <div> 
        <span>待处理订单</span>
        <span>--</span>
      </div>
      <i class="fg_icon"></i>
      <div>
        <span>进行中的订单</span>
        <span>--</span>
      </div>
    </div>
    <div style="display: flex;padding: 10px 20px;">
      <div style="width: 50%;text-align: center;">
        <button class="order-btn" @click="goCompleteInformation()">补全信息</button>
      </div>
      <div style="width: 50%;text-align: center;">
        <button class="order-btn" @click="goPageCarPosition()" style="margin-left: 10px;">获取车辆位置</button>
      </div>
    </div>
    <!-- 功能事件 -->
    <div class="domain" >
      <div class="domin_left" @click="goPage(1)">
        <van-image
        fit="fill"
          :src="require('../../assets/img/home/home_icon_PD.png')"
        />
        <span > 派单</span>
      </div>
    <div class="domin_center" @click="goPage(2)">
       <van-image
        fit="contain"
          :src="require('../../assets/img/home/home_icon_YLJK.png')"
        />
        <span style="color:#ffffff" >监控中心</span>
    </div>
      <div class="domin_rigth" @click="goPage(3)">
       <van-image
       fit="fill"
          :src="require('../../assets/img/home/home_icon_TJYL.png')"
        />
        <span class="text"> 添加运力</span>
      </div>
    </div>

  </div>
</template>
<script>
import NavBar from '@/components/NavBar/NavBar.vue'
export default {
  components: {
    NavBar
  },
  data() {
    return {
      navInfo:{
        title:'嗖56-承运商',
        rigth:true,
        menu:true
      },
      show:false,
      isHas:true
    }
  },
  created() {
    // console.log(this.$store.state.isHas);
  },
  mounted() {
    
  },
  methods: {
    // 补全司机信息
    goCompleteInformation() {
      this.$router.push(`/completeInformation`)
    },
    // 查询车辆位置
    goPageCarPosition() {
      this.$router.push(`/vehiclePositioning`)
    },
    //去跳转页面，根据type，和是否有计划订单
    goPage(type) {
      // console.log(type);
      this.isHas = this.$store.state.isHas
      if (type==2) {
        // console.log(this.isHas);
        if (this.isHas==true) {
          //有承运计划 跳转到运力监控有计划和运力栏
          this.$router.push(`/planWaybill`)
        }else if(this.isHas==false){
          //没有承运计划 跳转到运力监控 只有运力栏
          this.$router.push(`/regularWaybill`)
        }
      } else if(type==3) {
       this.$router.push(`/register/index`)
      } else if(type == 1) {
        this.$router.push(`/orderlist`)
      }
    },
    //退出登录
    exitLogin() {
      // console.log('退出登录，清除cookie');
      this.$cookie.delete('userName')
      this.$cookie.delete('passWord')
      this.$router.push('/login')
    }
  },
  //路由守卫缓存计划运力监控的组件状态
   beforeRouteLeave(to,from,next) {
    if (to.name=='PlanWaybill') {
        to.meta.KeepAlive = false
        next();
    } else {
      next();
    }
  },
  

}
</script>
<style lang="scss" scoped>
.container {
  
  width: 100vw;
  height: 100vh;
  overflow: auto;
  .img_top {
    width: 100vw;
    height: 6.53rem; 
    .van-image {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .order_info {
    display: flex;
    height: 8vh;
    padding-top: 10vw;
    border-bottom: 0.27rem solid #f5f5f5;
    font-size: 0.37rem;
    div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span{
        padding-bottom: 0.37rem;
      }
    }
    .fg_icon {
      height: 10vw;
      // width: 0.2vw;
      border: 1px solid #f2f2f2;
    }
  }
  .order-btn {
    width: 120px;
    height: 40px;
    // flex: 1;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    line-height: 1;
    white-space: nowrap;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    padding: 3px 5px;
    background: #ff6600;
    user-select: none;
    vertical-align: middle;
    -webkit-appearance: none;
    box-sizing: border-box;
    outline: 0;
    box-sizing: border-box;
    border-color: currentcolor;
    border-style: solid;
    border-width: 0;
    // padding: 12px 15px;
    border-radius: 3px;
  }
  .domain {
    position: relative;
    width: 100vw;
    height: 6.6rem;
    display: flex;
    justify-content: space-between;
    .van-image {
      width: 100%;
      height: 100%;
    }
    .domin_left {
      position: relative;
      width: 4.21rem;
      margin-left: 0.43rem;
      margin-top: 0.2rem;
       span {
        position: absolute;
        bottom: 0.8rem;
        left: 1rem;
        font-weight: bold;
        font-size: 0.43rem;
      }
    }
    .domin_rigth {
      position: relative;
      width: 4.21rem;
      margin-right: 0.43rem;
      margin-top: 0.2rem;
      span {
        position: absolute;
        bottom: 0.8rem;
        left: 1.85rem;
        font-weight: bold;
        font-size: 0.43rem;
      }
    }
    .domin_center {
      z-index: 9;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1.42rem;
      margin-top: -1.3rem;
      height: 2.83rem;
      width: 2.83rem;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      span {
        position: absolute;
        top: 0;
        text-align: center;
        line-height: 3rem;
        font-size: 0.37rem;
      }
    }
  }
  .van-action-sheet__content {
    padding-bottom: 20px;
  }
}
.text {
  color: #061f3e;
}
.destoryLogin {
  position: absolute;
  top: 0;
  right: 5px;
  width: 7vh;
  height: 5vh;
  z-index: 999;
  .destoryLogin_box {
    height: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .destoryLogin_box_img {
      padding-left: 0.2rem;
      height: 0.64rem;
      width: 0.64rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .destoryLogin_box_text {
      flex: 1;
      height: 0.64rem;
      line-height: 0.64rem;
    }
  }
}
 .border_sjx {
      z-index: 9999;
      position: absolute;
      right: 0.2rem;
      top: -0.49rem;
      width: 0;
      height: 0;
      border: 0.26rem solid;
      border-color: transparent transparent #FFFFFF;
    }
/deep/.van-popup--top {
  height: 1.2rem;
  right: 0.2rem;
  left: unset;
  top: 0.2rem;
  font-size: 0.37rem;
  width: 2.7rem;
  border-radius: 0.2rem;
}
/deep/.van-dropdown-menu__bar {
  background: unset;
}
/deep/.van-dropdown-menu__title {
  opacity: 0;
}
/deep/.van-popup {
  overflow: visible;
  background: #ffffff;
}
</style>